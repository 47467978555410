import React from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import SlicesZone from "../containers/Sliceszone"
import { ContentWrapper } from "../containers/alpacked.style"

const ServicesTemplate = ({ data, location }) => {
  const {
    body,
    seo_title,
    seo_short_description,
    seo_description,
    image_link_preview,
  } = data.prismicServicesPage.data

  const hasFaq = body.find(el => el.__typename === "PrismicServicesPageBodyFaq")
    ?.items

  return (
    <>
      <SEO
        hasFaq={hasFaq}
        canonical={
          seo_title.text === "DevOps Consulting"
            ? "https://alpacked.io/consulting/devops-consulting"
            : null
        }
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname={location.pathname}>
        <ContentWrapper>
          <SlicesZone
            content={body}
            breadCrumbsContent={{
              category: { title: "Services", url: "services" },
              page: {
                title: data.prismicServicesPage.data.title.text,
              },
            }}
          />
          <Consultation extraPadding pathname={location.pathname} />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withPreview(ServicesTemplate)

export const servicesPage = graphql`
  query servicesPageContent($slug: String!) {
    prismicServicesPage(uid: { eq: $slug }) {
      uid
      data {
        title {
          text
        }
        seo_title {
          text
        }
        seo_short_description {
          text
        }
        seo_description {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        title {
          text
        }
        category
        body {
          ... on PrismicServicesPageBodyBanner {
            id
            slice_type
            items {
              subtitle
              title {
                html
              }
              subtitle
              button_a_link
              button_a_title
              button_b_link
              button_b_title
              image {
                url
              }
            }
          }
          ... on PrismicServicesPageBodyRelated {
            id
            slice_type
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
            }
            items {
              intro
              content {
                html
              }
              icon {
                url
              }
              related_service_title {
                text
              }
              link_to_service {
                slug
              }
            }
          }
          ... on PrismicServicesPageBodyIntroduction {
            id
            primary {
              original_image_scale
              title1 {
                html
              }
              intro_text {
                html
              }
              title_colored {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicServicesPageBodyBreadcrumbs {
            id
            slice_type
            primary {
              enabled
            }
          }
          ... on PrismicServicesPageBodyContent {
            id
            slice_type
            items {
              image_position
              title1 {
                html
              }
              title_colored {
                html
              }
              free_content {
                html
              }
            }
          }
          ... on PrismicServicesPageBodyBenefits1 {
            id
            slice_type
            primary {
              number_of_columns
              description {
                html
              }
              title1 {
                html
              }
              title_colored {
                html
              }
              title_width
            }
            items {
              benefit_description
              benefit_title {
                text
              }
              icon {
                url
              }
            }
          }
          ... on PrismicServicesPageBodyStatistics {
            id
            slice_type
            items {
              subtitle
              title {
                html
              }
            }
          }
          ... on PrismicServicesPageBodyServices {
            id
            slice_type
            primary {
              number_of_columns
              description {
                html
              }
              title1 {
                html
              }
              title_colored {
                html
              }
            }
            items {
              service_name {
                text
              }
              description {
                html
              }
              image {
                url
              }
            }
          }
          ... on PrismicServicesPageBodyFaq {
            id
            slice_type
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
            }
            items {
              answer {
                html
              }
              question {
                text
              }
            }
          }
          ... on PrismicServicesPageBodyBenefits {
            id
            slice_type
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
              title_width
              description {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400, quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            items {
              description: benefit_description {
                html
              }
              benefit_title {
                text
              }
            }
          }
          ... on PrismicServicesPageBodyQuote {
            id
            slice_type
            primary {
              quote_text
              author_occupation
              author_name
              author_image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicesPageBodyTwoColumnContent {
            id
            slice_type
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
              description {
                html
              }
              image_position
              column_a {
                html
              }
              column_b {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
          ... on PrismicServicesPageBodyNumberedList {
            id
            slice_type
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
              subtitle {
                html
              }
            }
            items {
              title1 {
                text
              }
              numberedListContent: content
            }
          }
          ... on PrismicServicesPageBodyTechnologies {
            id
            slice_type
            primary {
              title1 {
                html
              }
              title_colored {
                html
              }
              description {
                html
              }
            }
            items {
              image {
                url
              }
            }
          }
          ... on PrismicServicesPageBodyTable {
            id
            slice_type
            primary {
              desTable: description {
                html
              }
              table_head_column_a
              table_head_column_b
              table_head_column_c
              table_head_column_d
              title1 {
                html
              }
              title_colored {
                html
              }
            }
            items {
              raw_column_b
              row_column_a
              raw_column_c
              raw_column_d
            }
          }
          ... on PrismicServicesPageBodyRelatedBlogPosts {
            id
            slice_type
            items {
              related_blog_posts {
                document {
                  ... on PrismicBlogPost {
                    id
                    uid
                    tags
                    first_publication_date(formatString: "MMM DD, YYYY")
                    data {
                      category
                      featured_article
                      intro_text
                      title {
                        text
                      }
                      image {
                        localFile {
                          childImageSharp {
                            fluid(maxWidth: 700, quality: 72) {
                              ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                          }
                        }
                      }
                      author {
                        slug
                        document {
                          ... on PrismicAuthor {
                            id
                            data {
                              name {
                                text
                              }
                              occupation {
                                text
                              }
                              image {
                                localFile {
                                  childImageSharp {
                                    fluid(maxWidth: 60, quality: 72) {
                                      ...GatsbyImageSharpFluid_withWebp_noBase64
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
